@font-face {
    font-family: 'Google Sans Display';
    src: url('GoogleSansDisplay-Medium.woff2') format('woff2'),
        url('GoogleSansDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans Display';
    src: url('GoogleSansDisplay-BoldItalic.woff2') format('woff2'),
        url('GoogleSansDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans Display';
    src: url('GoogleSansDisplay-Italic.woff2') format('woff2'),
        url('GoogleSansDisplay-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans';
    src: url('GoogleSans-Medium.woff2') format('woff2'),
        url('GoogleSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans Display';
    src: url('GoogleSansDisplay-Regular.woff2') format('woff2'),
        url('GoogleSansDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans Display';
    src: url('GoogleSansDisplay-MediumItalic.woff2') format('woff2'),
        url('GoogleSansDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans';
    src: url('GoogleSans-MediumItalic.woff2') format('woff2'),
        url('GoogleSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans';
    src: url('GoogleSans-Regular.woff2') format('woff2'),
        url('GoogleSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans';
    src: url('GoogleSans-Italic.woff2') format('woff2'),
        url('GoogleSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans Display';
    src: url('GoogleSansDisplay-Bold.woff2') format('woff2'),
        url('GoogleSansDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans';
    src: url('GoogleSans-BoldItalic.woff2') format('woff2'),
        url('GoogleSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans';
    src: url('GoogleSans-Bold.woff2') format('woff2'),
        url('GoogleSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

